import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import { PROFILE_IMAGE_MAX_HEIGHT, PROFILE_IMAGE_MAX_WIDTH } from '@Constants/configs';
import Yup from '@Utils/YupUtils';

import type { UpdateAccountFormProps, UpdateAccountFormValues } from './UpdateAccountForm.props';

export const UpdateAccountForm = ({ onSubmit, ...props }: UpdateAccountFormProps) => {
  const accountSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    language: Yup.string().required(),
    phoneNumber: Yup.string(),
    image: Yup.mixed()
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ACCOUNT.FORM.UPDATE'
  });

  const formRef = useRef<FormMethods<UpdateAccountFormValues>>(null);

  const handleSumbit = useCallback(
    async (data: UpdateAccountFormValues) => {
      await onSubmit?.(data);
    },
    [onSubmit]
  );

  return (
    <Form<UpdateAccountFormValues>
      {...props}
      validationSchema={accountSchema}
      ref={formRef}
      onSubmit={handleSumbit}
    >
      <Form.Input.Text
        name="firstName"
        label={t('FIELDS.FIRSTNAME.LABEL')}
        inputProps={{ placeholder: t('FIELDS.FIRSTNAME.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="lastName"
        label={t('FIELDS.LASTNAME.LABEL')}
        inputProps={{ placeholder: t('FIELDS.LASTNAME.PLACEHOLDER') }}
      />

      <Form.Input.Language
        name="language"
        label={t('FIELDS.LANGUAGE.LABEL')}
        inputProps={{
          placeholder: t('FIELDS.LANGUAGE.PLACEHOLDER'),
          defaultValue: props.initialValues?.language
        }}
      />

      <Form.Input.Text
        name="phoneNumber"
        label={t('FIELDS.PHONENUMBER.LABEL')}
        inputProps={{ placeholder: t('FIELDS.PHONENUMBER.PLACEHOLDER') }}
      />

      <Form.Input.File
        name="image"
        label={t('FIELDS.IMAGE.LABEL', {
          width: PROFILE_IMAGE_MAX_WIDTH,
          height: PROFILE_IMAGE_MAX_HEIGHT
        })}
      />

      <Button isSubmit>{t('SUBMIT')}</Button>
    </Form>
  );
};
