import type { IRoom, Measurement } from '@Services/Room';

export interface FrequencyChartProps {
  className?: string;
  room: IRoom;
}

export enum ChartBorderTypes {
  ROUND = 'round',
  BUTT = 'butt',
  SQUARE = 'square'
}

export interface MeasurementChartEntry {
  data: Measurement;
  label: string;
  borderColor: string;
  borderDash?: number[];
  borderCapStyle: ChartBorderTypes;
}
