import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import type { CustomerUpdateRequest } from '@Services/Customer';
import Yup from '@Utils/YupUtils';

import type { UpdateCustomerFormProps, UpdateCustomerFormValues } from './UpdateCustomerForm.props';

const UpdateCustomerSchema = Yup.object({
  name: Yup.string().required(),
  number: Yup.string(),
  email: Yup.string().required().email(),
  street: Yup.string(),
  houseNumber: Yup.string(),
  postalCode: Yup.string(),
  city: Yup.string(),
  language: Yup.string(),
  contactPerson: Yup.string(),
  country: Yup.string()
});

export const UpdateCustomerForm = ({ onSubmit, ...props }: UpdateCustomerFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.CUSTOMER.FORM.CREATE_UPDATE_CUSTOMER'
  });

  const formRef = useRef<FormMethods<UpdateCustomerFormValues>>(null);

  const submitHandler = useCallback(
    async (data: CustomerUpdateRequest) => {
      await onSubmit?.(data);

      formRef.current?.reset();
    },
    [onSubmit]
  );

  return (
    <Form<UpdateCustomerFormValues>
      {...props}
      validationSchema={UpdateCustomerSchema}
      ref={formRef}
      onSubmit={submitHandler}
    >
      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.GENERAL.NAME.PLACEHOLDER') }}
        name="name"
        isRequired
        label={t('SECTIONS.GENERAL.NAME.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.GENERAL.NUMBER.PLACEHOLDER') }}
        name="number"
        label={t('SECTIONS.GENERAL.NUMBER.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.STREET.PLACEHOLDER') }}
        name="street"
        label={t('SECTIONS.CONTACT.STREET.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.HOUSE_NUMBER.PLACEHOLDER') }}
        name="houseNumber"
        label={t('SECTIONS.CONTACT.HOUSE_NUMBER.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.POSTAL_CODE.PLACEHOLDER') }}
        name="postalCode"
        label={t('SECTIONS.CONTACT.POSTAL_CODE.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.CITY.PLACEHOLDER') }}
        name="city"
        label={t('SECTIONS.CONTACT.CITY.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.CONTACT_PERSON.PLACEHOLDER') }}
        name="contactPerson"
        label={t('SECTIONS.CONTACT.CONTACT_PERSON.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.EMAIL.PLACEHOLDER') }}
        name="email"
        isRequired
        label={t('SECTIONS.CONTACT.EMAIL.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.PHONE.PLACEHOLDER') }}
        name="phone"
        isRequired
        label={t('SECTIONS.CONTACT.PHONE.LABEL')}
      />

      <Form.Input.Text
        name="country"
        label={t('SECTIONS.CONTACT.COUNTRY.LABEL')}
        inputProps={{ placeholder: t('SECTIONS.CONTACT.COUNTRY.PLACEHOLDER') }}
      />

      <Form.Input.Language
        name="language"
        label={t('SECTIONS.CONTACT.LANGUAGE.LABEL')}
        inputProps={{
          hasPlaceholder: false,
          defaultValue: props.initialValues?.language
        }}
      />

      <Button isSubmit>{t('UPDATE')}</Button>
    </Form>
  );
};
