import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FrequencyChart, SolutionCardRow } from '@Components/container';
import { RoomDetail } from '@Components/network';
import type { DropdownItem } from '@Components/ui';
import { ButtonArea, RoomCardRow, SpacingGroup, Statistic, Tabs } from '@Components/ui';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import { useRoomDelete, useRoomDuplicate } from '@Hooks/Rooms';
import type { Score } from '@Services/Room';
import { isAdvisor } from '@Utils/AuthUtils';

import { RoomAdviceDetails } from '../RoomAdviceDetails/RoomAdviceDetails';
import styles from './RoomCard.module.scss';
import type { RoomCardProps } from './RoomCard.props';

export const RoomCard = ({ room, advisorId, isDefaultOpened }: RoomCardProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'DOMAIN.ROOM' });
  const { mutateAsync: handleDeleteRoom } = useRoomDelete();
  const { mutateAsync: handleDuplicateRoom } = useRoomDuplicate();
  const { data: currentAdvisor } = useAuthGetMe();
  const { projectId } = useParams();
  const appRoutes = useAppRoutes();
  const dropdownItems: DropdownItem[] = [
    {
      label: t('DROPDOWN_OPTIONS.DUPLICATE.LABEL'),
      onSelect: () => handleDuplicateRoom(room)
    },
    {
      label: t('DROPDOWN_OPTIONS.DELETE.LABEL'),
      onSelect: () => handleDeleteRoom(room)
    }
  ];

  const isCurrentAdvisor = isAdvisor() && !!currentAdvisor && advisorId === currentAdvisor?.id;

  const listItems = Object.entries(room.score).map(([key, value]) => ({
    label: t(`LIST_ITEMS.${(key as keyof Score).toUpperCase()}` as any),
    value
  }));

  return (
    <RoomCardRow
      dropdownItems={isCurrentAdvisor ? dropdownItems : undefined}
      isDefaultOpened={isDefaultOpened}
      title={room.name}
      listItems={listItems}
      specList={t('SPEC_LIST', {
        returnObjects: true,
        roomType: room.roomType.name,
        surface: room.dimensions.surface
      })}
      imageSrc={room.roomType.image}
    >
      <Tabs>
        <Tabs.Item value="products" label={t('TABS.PRODUCTS.TITLE')}>
          <SpacingGroup isVertical>
            <RoomAdviceDetails advice={room.advice} />

            {isCurrentAdvisor && (
              <ButtonArea
                to={appRoutes.getAdvisorProjectRoomAddProduct(projectId, room.id).to}
                icon={faPlus}
              >
                {t('TABS.PRODUCTS.ADD')}
              </ButtonArea>
            )}

            {room.solutions?.map((solution) => {
              return (
                <SolutionCardRow
                  isCurrentAdvisor={isCurrentAdvisor}
                  roomId={room.id}
                  projectId={projectId}
                  key={solution.id}
                  solution={solution}
                />
              );
            })}
          </SpacingGroup>
        </Tabs.Item>

        <Tabs.Item value="measurements" label={t('TABS.MEASUREMENTS.TITLE')}>
          <SpacingGroup isVertical>
            {isCurrentAdvisor && (
              <div className={styles.ButtonGroup}>
                <ButtonArea
                  to={appRoutes.getAdvisorProjectRoomAddMeasurements(projectId, room.id).to}
                  icon={faPlus}
                >
                  {t('TABS.MEASUREMENTS.ADD')}
                </ButtonArea>

                <ButtonArea
                  to={appRoutes.getAdvisorProjectRoomAddPostMeasurements(projectId, room.id).to}
                  icon={faPlus}
                />
              </div>
            )}

            <FrequencyChart room={room} />
          </SpacingGroup>
        </Tabs.Item>

        <Tabs.Item value="details" label={t('TABS.DETAILS.TITLE')}>
          {isCurrentAdvisor ? (
            <RoomDetail room={room} />
          ) : (
            <SpacingGroup>
              {Object.entries(room.dimensions).map(([key, value]) => {
                return (
                  <Statistic key={key} label={t(`DETAILS.${key}` as any)}>
                    {value}
                  </Statistic>
                );
              })}
            </SpacingGroup>
          )}
        </Tabs.Item>
      </Tabs>
    </RoomCardRow>
  );
};
