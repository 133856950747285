import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Chart, SpacingGroup, StatisticScore } from '@Components/ui';
import { isMeasurementSet } from '@Utils/MeasurementUtils';
import { getTheme } from '@Utils/ThemeUtils';
import { i18n } from '@Utils/TranslationUtils';

import styles from './FrequencyChart.module.scss';
import {
  type FrequencyChartProps,
  type MeasurementChartEntry,
  ChartBorderTypes
} from './FrequencyChart.props';
import { Scales } from './internal/constants';

export const FrequencyChart = ({ className, room }: FrequencyChartProps) => {
  const classNames = cn(styles.FrequencyChart, className);
  const { t } = useTranslation();
  const { color } = getTheme();

  const percentageFormatter = new Intl.NumberFormat(i18n.language, { style: 'percent' });

  const datasets: MeasurementChartEntry[] = [
    {
      data: room.measurements.current,
      label: t('COMPONENTS.FREQUENCY_CHART.LABELS.CURRENT'),
      borderColor: color.primary.default,
      borderDash: [5, 5],
      borderCapStyle: ChartBorderTypes.ROUND
    },
    {
      data: room.measurements.expected,
      label: t('COMPONENTS.FREQUENCY_CHART.LABELS.EXPECTED'),
      borderColor: color.secondary.default,
      borderDash: [5, 5],
      borderCapStyle: ChartBorderTypes.ROUND
    },
    {
      data: room.measurements.ideal,
      label: t('COMPONENTS.FREQUENCY_CHART.LABELS.IDEAL'),
      borderColor: color.progress.default,
      borderCapStyle: ChartBorderTypes.ROUND
    }
  ];

  if (isMeasurementSet(room.measurements.realised)) {
    datasets.push({
      data: room.measurements.realised,
      label: t('COMPONENTS.FREQUENCY_CHART.LABELS.REALISED'),
      borderColor: color.black.default,
      borderDash: [5, 5],
      borderCapStyle: ChartBorderTypes.ROUND
    });
  }

  const localisedScales = {
    x: {
      ...Scales?.x,
      title: { ...Scales?.x?.title, text: t('COMPONENTS.FREQUENCY_CHART.FREQUENCY') }
    },
    y: {
      ...Scales?.y,
      title: { ...Scales?.y?.title, text: t('COMPONENTS.FREQUENCY_CHART.DURATION') }
    }
  };

  return (
    <section className={classNames}>
      <SpacingGroup isVertical spacing={1.5}>
        <StatisticScore color={color.primary.default} label={t('DOMAIN.ROOM.LIST_ITEMS.CURRENT')}>
          {percentageFormatter.format(room.score.current / 100)}
        </StatisticScore>

        <StatisticScore
          color={color.secondary.default}
          label={t('DOMAIN.ROOM.LIST_ITEMS.EXPECTED')}
        >
          {percentageFormatter.format(room.score.expected / 100)}
        </StatisticScore>
      </SpacingGroup>

      <Chart
        data={{
          datasets
        }}
        options={{
          scales: localisedScales,
          plugins: {
            tooltip: {
              intersect: false,
              mode: 'index',
              usePointStyle: true
            }
          }
        }}
      />
    </section>
  );
};
