import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { JsonParam, NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { LazyList } from '@Components/layout';
import { Anchor, Text } from '@Components/ui';
import { Locale } from '@Constants/enums';
import { useAlgoliaGetProducts } from '@Hooks/Algolia/useAlgoliaGetProducts';
import { useAuthGetMe } from '@Hooks/Auth';

import { Filter } from './internal/components';
import { Archive } from './internal/components/Archive/Archive';
import styles from './Products.module.scss';

export const Products = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PRODUCT.OVERVIEW'
  });
  const currentAdvisor = useAuthGetMe();

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [query, setQuery] = useQueryParams(
    {
      page: withDefault(NumberParam, 0),
      filter: JsonParam
    },
    {
      updateType: 'pushIn',
      removeDefaultsFromUrl: true
    }
  );

  const { data, ...infiniteQuery } = useAlgoliaGetProducts({
    perPage: 12,
    filters: query.filter,
    page: query.page,
    facets: ['appliances.title', 'material.title', 'solutions.title', 'type.title'],
    locale:
      typeof currentAdvisor.data === 'object' ? `${currentAdvisor?.data.language}` : `${Locale.NL}`
  });

  const lastPage = data?.pages[data.pages.length - 1];

  const allResults = data?.pages
    .map((pageData) => {
      return pageData.hits;
    })
    .flat(1);

  return (
    <div className={styles.Products}>
      <Container>
        <Text.H1 className={styles.Title}>{t('TITLE')}</Text.H1>

        <Anchor className={styles.OpenFiltersButton} onClick={() => setIsFilterOpen(true)}>
          {t('FILTERS.OPEN_BUTTON')}
        </Anchor>
      </Container>

      <Container>
        <Row>
          <Col lg={4}>
            <Filter
              filterGroups={lastPage?.facets ?? {}}
              initialFilters={query.filter}
              isOpen={isFilterOpen}
              onClose={() => setIsFilterOpen(false)}
              onChange={(filters) => setQuery({ filter: filters })}
            />
          </Col>

          <Col lg={8}>
            <LazyList {...infiniteQuery}>
              <Archive products={allResults ?? []} />
            </LazyList>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
