import type { IBaseModel } from '@Constants/interfaces';
import type { IProject } from '@Services/Project';
import type { IRoomtype } from '@Services/Roomtype';
import type { ISolution } from '@Services/Solution';

export interface BaseRoom extends IBaseModel {
  name: string;
  roomType: IRoomtype;
}

export interface Score {
  current: number;
  expected: number;
}

export interface IRoom extends BaseRoom {
  dimensions: Dimensions;
  measurements: Measurements;
  score: Score;
  advice?: Advice;
  solutions: ISolution[];
}

export type Advice = Array<{ title: string; label: string; value: number }>;

export interface Dimensions {
  surface: number;
  minHeight: number;
  maxHeight: number;
}

export type Measurement = [number, number, number, number, number, number];

export interface Measurements {
  current: Measurement;
  expected: Measurement;
  ideal: Measurement;
  realised: Measurement;
}

export enum MeasurementType {
  INITIAL = 'initial',
  REALISED = 'realised'
}

export interface RoomMeasurements {
  type: MeasurementType;
  MeasuredValue125Hz: number;
  MeasuredValue250Hz: number;
  MeasuredValue500Hz: number;
  MeasuredValue1KHz: number;
  MeasuredValue2KHz: number;
  MeasuredValue4KHz: number;
}

export interface RoomCreateRequest extends Omit<BaseRoom, 'id'> {
  project: Pick<IProject, 'id'>;
}

export interface RoomDeleteRequest extends IRoom {}

export interface RoomCreateResponse extends BaseRoom {}

export interface RoomUpdateResponse extends IRoom {}

export interface RoomUpdateRequest extends IRoom {}

export interface RoomDuplicateRequest extends IRoom {}

export interface RoomDuplicateResponse extends IRoom {}

export interface RoomAddMeasurementRequest extends Pick<IRoom, 'id'>, RoomMeasurements {}

export interface RoomAddMeasurementResponse extends IRoom {}

export interface RoomDeleteSolutionRequest {
  id: string;
  solutionId: string;
}
export interface RoomAddSolutionRequest {
  id: string;
  productId: string;
  name: string;
}
