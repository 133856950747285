import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { HtmlRenderer } from '@Components/layout';
import { Button, Card, Text } from '@Components/ui';

import styles from './ProductCard.module.scss';
import type { ProductCardProps } from './ProductCard.props';

export const ProductCard = ({ className, product, ...props }: ProductCardProps) => {
  const classNames = cn(styles.ProductCard, className);
  const { t } = useTranslation('translation', { keyPrefix: 'COMPONENTS.PRODUCT_CARD' });

  const { title, thumbnailUrl } = product;

  return (
    <Card className={classNames} {...props}>
      <Card.Image src={thumbnailUrl} ratio={350 / 200} />

      <Card.Main className={styles.Main}>
        <Text.H1 color="primary" className={styles.Title}>
          {title}
        </Text.H1>

        <HtmlRenderer content={product.excerpt} />

        {!!product.whitePaperUrl && (
          <Button
            type="download"
            target="_self"
            href={product.whitePaperUrl}
            color="secondary"
            isOutline
          >
            {t('READ_WHITEPAPER')}
          </Button>
        )}
      </Card.Main>
    </Card>
  );
};
